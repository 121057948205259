@import url("https://fonts.googleapis.com/css2?family=Rubik+Dirt&family=Rubik:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Rubik+Dirt&family=Rubik:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Pacifico&family=Rubik+Dirt&family=Rubik:wght@300;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: #0b0c10;
}

body::-webkit-scrollbar-thumb {
  background: #45a29e;
  border: 5px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}
