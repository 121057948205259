.custom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  margin-bottom: -110px;
}

.custom svg {
  position: relative;
  display: block;
  width: calc(139% + 1.3px);
  height: 285px;
}

.custom .shape-fill {
  fill: #1c2e4c;
}

/* style screen skills */

.custom-shape-skills {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-skills svg {
  position: relative;
  display: block;
  width: calc(161% + 1.3px);
  height: 300px;
}

.custom-shape-skills .shape-fill {
  fill: #1c2e4c;
}
@media only screen and (max-width: 600px) {
  .skill {
    /* Estilos específicos para .skill en pantallas pequeñas */
    /* padding-bottom: 500px; */
  }
  .skillmb{
    margin-top: -350px;
  }
  .horemb{
    margin-top: -100px;
  }
}
/* Estilos para pantallas medianas (tabletas, entre 600px y 900px) */
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .skill {
    /* Estilos específicos para .skill en pantallas medianas */
    /* padding: 218px; */
    font-size: 100px !important;
  }
  .skillmb{
    margin-top: -380px;
  }
}

@media only screen and (max-width: 400px) {
  .skill {
    /* Estilos específicos para .skill en pantallas pequeñas */
   /*  padding-bottom: 500px; */
  }
}
@media only screen and (max-width: 820px) and (max-width: 900px){
  .skillmb {
    /* Estilos específicos para .skill en pantallas pequeñas */
   /*  padding-bottom: 500px; */
   margin-top: 310px;
  }
}
/* Estilos por defecto para .skill */
.skill {
  font-size: 16px; /* Tamaño de letra base */
}

/* Estilos para iPhone 13 */
@media only screen and (min-width: 390px) and (max-width: 844px) and (orientation: portrait) {
  .skill {
    font-size: 18px; /* Ajusta según tus necesidades */
  }
  
}

@media only screen and (min-width: 844px) and (max-width: 390px) and (orientation: landscape) {
  .skill {
    font-size: 18px; /* Ajusta según tus necesidades */
  }
}

/* Estilos para iPhone 7 Plus */
@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation: portrait) {
  .skill {
    font-size: 17px; /* Ajusta según tus necesidades */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .skill {
    font-size: 17px; /* Ajusta según tus necesidades */
  }
  .skillmb{
    margin-bottom: 80px;
  }
}

/* Estilos para iPhone SE */
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation: portrait) {
  .skill {
    font-size: 16px; /* Ajusta según tus necesidades */
  }
  .skillmb{
    margin-top: -280px;
  }
  .horemb{
    margin-top: -100px;
  }
}

@media only screen and (min-width: 667px) and (max-width: 375px) and (orientation: landscape) {
  .skill {
    font-size: 16px; /* Ajusta según tus necesidades */
  }
}
